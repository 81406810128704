<template>
  <CardToolbar
    :title="title"
    :description="description"
    :show-toolbar-buttons="true"
    v-loading="loading"
  >
    <template v-slot:toolbar>
      <div class="d-flex align-items-center">
        <input
          class="form-control form-control-solid me-3"
          placeholder="Search"
          v-model="filterQuery"
          @input="onFilterGenres"
        />
        <div
          class="spinner-border text-primary spinner-border-sm"
          role="status"
          v-if="filterLoading"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <el-skeleton :loading="loading" animated>
        <template #default>
          <div class="genres">
            <el-row>
              <el-col
                :span="8"
                v-for="(genre, index) in filteredGenres"
                :key="index"
              >
                <h6
                  :class="['cursor-pointer ', 'genre-name']"
                  @click="getGenre(genre.name)"
                >
                  {{ genre.name }}
                </h6>
              </el-col>
            </el-row>
          </div>
        </template>
      </el-skeleton>
    </template>
  </CardToolbar>
  <!--  <CardToolbar-->
  <!--    :title="'Recently Imported Genres'"-->
  <!--    :description="null"-->
  <!--    :show-toolbar-buttons="false"-->
  <!--    v-loading="loading"-->
  <!--    v-if="recentlyImported.length > 0"-->
  <!--    :padding="false"-->
  <!--  >-->
  <!--    <template v-slot:body>-->
  <!--      <el-skeleton :loading="loading" animated>-->
  <!--        <template #default>-->
  <!--          <div class="imported-genres">-->
  <!--            <el-row>-->
  <!--              <el-col-->
  <!--                :span="8"-->
  <!--                v-for="(genre, index) in recentlyImported"-->
  <!--                :key="index"-->
  <!--              >-->
  <!--                <h6>-->
  <!--                  {{ genre.name }}-->
  <!--                </h6>-->
  <!--              </el-col>-->
  <!--            </el-row>-->
  <!--          </div>-->
  <!--        </template>-->
  <!--      </el-skeleton>-->
  <!--    </template>-->
  <!--  </CardToolbar>-->
</template>
<script>
import CardToolbar from "../../common/components/CardToolbar";
import { ref, onMounted } from "vue";
import { getChartmetricGenres } from "@/api/genreResult";
export default {
  name: "AnalyzedGenre",
  emits: ["getArtistsByGenre"],
  components: { CardToolbar },
  props: {
    title: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
  },
  setup(props, ctx) {
    const genres = ref([]);
    const filteredGenres = ref([]);
    const filterQuery = ref("");
    const loading = ref(false);
    const error = ref(false);
    const filterLoading = ref(false);
    const recentlyImported = ref([]);

    onMounted(async () => {
      try {
        loading.value = true;
        const { data } = await getChartmetricGenres();
        genres.value = data;
        // recentlyImported.value = genres.value.filter(function (genre) {
        //   return getDaysDifference(genre.lastScrapedAt) <= 1;
        // });
        filteredGenres.value = genres.value;
      } catch (e) {
        error.value = true;
        console.error(e);
      } finally {
        loading.value = false;
      }
    });

    // const getDaysDifference = (date) => {
    //   const currentDate = moment(new Date(), "YYYY,MM,DD");
    //   const lastScrapedAt = moment(date, "YYYY,MM,DD");
    //   return moment.duration(currentDate.diff(lastScrapedAt)).asDays();
    // };

    const onFilterGenres = () => {
      filterLoading.value = true;
      filteredGenres.value = genres.value.filter((genre) =>
        genre.name.includes(filterQuery.value)
      );
      filterLoading.value = false;
    };

    const getGenre = (genre) => {
      ctx.emit("pushGenreToRoute", genre);
    };
    return {
      filterQuery,
      filteredGenres,
      loading,
      filterLoading,
      recentlyImported,
      onFilterGenres,
      getGenre,
    };
  },
};
</script>
<style scoped>
.genres {
  max-height: 25rem !important;
  overflow-y: scroll;
}
.genre-name:hover {
  text-decoration: underline;
}
.genre-style {
  color: darkgray !important;
}
.genre-style:hover {
  text-decoration: none !important;
}
.imported-genres {
  max-height: 10rem !important;
  overflow-y: scroll;
}
</style>
