<template>
  <div class="artist-container" v-loading.fullscreen.lock="loading">
    <AnalyzedGenreNew
      :title="'Genres'"
      :description="'Analyzed Genre'"
      @pushGenreToRoute="pushGenreToRoute"
    />
    <SelectedGenreArtistsNew :genre="genre" />
  </div>
</template>
<script>
import AnalyzedGenreNew from "../components/AnalyzedGenreNew";
import SelectedGenreArtistsNew from "../components/SelectedGenreArtistsNew";
import { useRoute } from "vue-router";
import router from "@/router";
export default {
  name: "PreFilledGenresNew",
  components: {
    AnalyzedGenreNew,
    SelectedGenreArtistsNew,
  },
  data() {
    return {
      loading: false,
      genre: null,
      route: useRoute(),
    };
  },
  mounted() {
    if (this.route.query && this.route.query.genre) {
      this.getArtistsByGenre(this.route.query.genre);
    }
  },
  methods: {
    pushGenreToRoute(genre) {
      router.push(`/ai-artists-2?genre=${genre}`);
      this.genre = genre;
    },
    getArtistsByGenre(genre) {
      this.genre = genre;
    },
  },
};
</script>

<style lang="scss" scoped></style>
